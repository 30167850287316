<template>
  <validation-observer ref="gameSettingForm">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header>
              <b-card-title>ข้อมูลเกม และสถานะการให้บริการ</b-card-title>
            </b-card-header>
            <hr class="m-0">

            <b-card-body>
              <b-row>
                <b-col cols="6">
                  <b-img-lazy
                    :src="require('@/assets/images/game.png')"
                    fluid
                    class="w-100')"
                  />
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    name="file"
                    rules=""
                  > 
                    <b-form-group
                      label-for="file"
                    >
                      <b-form-file
                        id="file"
                        v-model="file"
                        accept="image/*"
                        browse-text="อัพโหลด"
                        :placeholder="file?'':'ยังไม่ได้เลือกไฟล์'"
                      />
                      <b-form-text>
                        <b-link
                          v-if="file"
                          class="text-danger"
                          @click="file = ''"
                        >
                          {{ $t('buttons.remove') }}
                        </b-link>
                      </b-form-text>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="gameId"
                    rules=""
                  >
                    <b-form-group
                      label="Game ID"
                      label-for="gameId"
                    >
                      <b-form-input
                        id="gameId"
                        :value="gameDetail.gameId || ''"
                        :state="errors[0] ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="gameName"
                    rules=""
                  >
                    <b-form-group
                      label="ชื่อเกม"
                      label-for="gameName"
                    >
                      <b-form-input
                        id="gameName"
                        :value="gameDetail.gameName || ''"
                        :state="errors[0] ? false : null"
                        placeholder=""
                      />
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    name="status"
                  >
                    <b-form-group
                      label="ชื่อเกม"
                      label-for="status"
                    >
                      <b-form-select
                        v-model="gameDetail.status"
                        :options="gameStatuses"
                      />
                      <b-form-text>
                        <span class="text-success">เปิดให้บริการ</span>  -   ผู้เล่นเข้าเล่นเกมได้ตามปกติ<br>
                        <span class="text-warning">ปิดชั่วคราว</span>    -   ผู้เล่นเห็นเมนูเกม แต่ไม่สามารถเข้าเล่นเกมได้<br>
                        <span class="text-danger">ปิดให้บริการ</span>   -   ผู้เล่นจะไม่เห็นเมนูเกม
                      </b-form-text>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <b-card no-body>
            <b-card-header>
              <div>
                <b-card-title>รายการ Blacklist </b-card-title>
              </div>
              <b-button
                variant="outline-primary"
                @click="onAddBlacklist"
              >
                เพิ่ม Blacklist
              </b-button>
              <BlacklistFormModal
                :game-id="gameId"
                @refetch="fetchData"
              />
            </b-card-header>
            <hr class="mt-0">
            <b-row class="mb-1 mr-1">
              <b-col />
              <b-col cols="3">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="search"
                    type="search"
                    placeholder="ชื่อตัวแทน"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- Table -->
            <b-table
              :items="agentBlacklist"
              :fields="fields"
              responsive
              show-empty
              hover
            >
              <template #cell(id)="data">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="onRemoveBlacklist(data.value)"
                >
                  ลบรายชื่อ
                </b-button>
              </template>
              <template #empty="">
                <p class="text-center text-muted">
                  ไม่มีตัวแทนที่โดน Blacklist
                </p>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>

      <ConfirmModal
        id="remove-blacklist-modal"
        title="ยืนยันการลบรายตัวแทนออกจาก Blacklist"
        subtitle="เมื่อลบรายชื่อออกไปแล้ว ตัวแทนรายนี้จะสามารถเปิดให้บริการเกมได้ตามปกติ"
        confirm-text="ยืนยัน"
        confirm-variant="primary"
        @confirm="onConfirmRemove"
      />
      <div class="d-flex justify-content-center">
        <b-button
          variant="outline-primary"
          class="mr-1"
          :to="`/games/list`"
        >
          ยกเลิก
        </b-button>
        <b-button
          variant="primary"
          type="submit"
        >
          ยืนยัน
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'GameDetail',
   components: {
    BlacklistFormModal: () => import('./components/BlacklistFormModal.vue'),
    ConfirmModal: () => import('../commons/ConfirmModal.vue'),
  },
  data() {
    return {
      file: null,
      search: '',
      onSearchTimeout: null,
      gameStatuses: [
        { value: '0', text: 'เปิดให้บริการ' },
        { value: '1', text: 'ปิดชั่วคราว' },
        { value: '2', text: 'ปิดให้บริการ' },
      ],
      fields: [
        {
          key: 'name',
          label: 'ชื่อตัวแทน',
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],
      selectedBlacklist: ''
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.game.isGettingGameDetail,
    }),
    ...mapGetters(['gameDetail', 'blacklist']),
    gameId() {
      const { params } = this.$route
      return params.id ? params.id : ''
    },
    agentBlacklist() {
      return [{ id: '1', name: 'Stop Fish 777'}]
    },
  },
  watch: {
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getAgents','getGameDetail', 'updateGameSetting', 'getBlacklist', 'removeBlacklist']),
    fetchData() {
      // this.getGameDetail(this.gameId)
      // this.getBlacklist(this.gameId)
      this.getAgents()
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onSubmit() {
      // this.updateGameSetting(this.gameId, this.gameDetail)
    },
    onAddBlacklist() {
      this.$bvModal.show('blacklist-form-modal')
    },
    onRemoveBlacklist(id) {
      this.selectedBlacklist = id
      this.$bvModal.show('remove-blacklist-modal')
    },
    onConfirmRemove() {
      // this.removeBlacklist()
      this.selectedBlacklist = ''
    }
  },
}
</script>
